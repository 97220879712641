import Vue from "vue";

/*TITLE*/
document.title = "Edificio Varadero | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Varadero";
Vue.prototype.$subtitle = "Entre el mar y el cielo";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Varadero";
Vue.prototype.$introSubtitle = "Entre el mar y el cielo";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_op04_ac4-residence-1l-nevada-193x138--20230113090112.jpg";
Vue.prototype.$image_kitchen = "cocina_op03_bottega-caliza-443x443-china-blanco-blanco-nubol-bottega-caliza--20230113090141.jpg";
Vue.prototype.$image_bath1 = "banopral_op03_bottega-caliza-443x443-bottega-caliza-333x592-marbella-stone-333x592--20230113090115.jpg";
Vue.prototype.$image_bath2 = "aseo_op01_bottega-acero-443x443-bottega-acero-333x592-marbella-stone-333x592--20230113100113.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_op04_ac4-residence-1l-nevada-193x138--20230113090112.jpg",
  },
  {
    src: "salon_op03_ac4-residence-1l-utah-193x138--20230113090120.jpg",
  },
  {
    src: "salon_op02_bottega-caliza-443x444--20230113090125.jpg",
  },
  {
    src: "salon_op01_bottega-acero-443x443--20230113090134.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina_op03_bottega-caliza-443x443-china-blanco-blanco-nubol-bottega-caliza--20230113090141.jpg",
  },
  {
    src: "cocina_op02_bottega-caliza-443x443-china-blanco-zinc-aged-dark--20230113090148.jpg",
  },
  {
    src: "cocina_op01_bottega-acero-443x443-china-blanco-etimoe-ice-frame-white--20230113090156.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banopral_op03_bottega-caliza-443x443-bottega-caliza-333x592-marbella-stone-333x592--20230113090115.jpg",
  },
  {
    src: "banopral_op02_bottega-acero-443x443-bottega-acero-333x592-spiga-bottega-acero-333x592--20230113090121.jpg",
  },
  {
    src: "banopral_op01_bottega-acero-443x443-bottega-acero-333x592-marbella-stone-333x592--20230113090129.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "aseo_op01_bottega-acero-443x443-bottega-acero-333x592-marbella-stone-333x592--20230113100113.jpg",
  },
  {
    src: "aseo_op02_bottega-acero-443x443-bottega-acero-333x592-spiga-bottega-acero-333x592--20230113100123.jpg",
  },
  {
    src: "aseo_op03_bottega-caliza-443x443-bottega-caliza-333x592-marbella-stone-333x592--20230113100134.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/VARADERO/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/NcPJwCBmNJo";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avda%20ronda%20del%20estero%20nº%205%20san%20fernando%20cádiz%20",
    text: "Avda Ronda del Estero nº 5 - San Fernando (Cádiz)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=real%20209%20san%20fernando%20cádiz%20",
    text: "Real 209 - San Fernando (Cádiz)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:956885189",
    text: "956885189",
  },
  {
    icon: "mdi-email",
    link: "mailto:hola@aticosinmobiliaria.net",
    text: "hola@aticosinmobiliaria.net",
  },
];
